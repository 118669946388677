// i18next-extract-mark-ns-start apple-pay-for-prestashop
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_applepay from 'images/prestashop_applepay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';

import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {
  BizumForPrestashopLink,
  InternalPageLink,
  PaypalForPrestashopLink,
  PrestashopPaymentGatewayLink,
  PricingLink,
  RedsysAlternativeLink,
  VisaMasterCardForPrestashopLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import {Bold} from 'components/StickyBanner';
import {BlogLink} from 'components/links/Blog';

const ApplePayForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="apple-pay-for-prestashop"
        title={t('Apple Pay for PrestaShop')}
        description={t(
          'Add the Apple Pay PrestaShop integration to your store to let customers pay with one of the most popular digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the PrestaShop Apple Pay integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Apple Pay is available to all iOS users and is one of the most popular digital
                wallets among consumers. Reach over 500 million people, improve the payment
                experience, and increase sales by accepting Apple Pay payments in your PrestaShop
                online store. Manage your entire payment stack from a single platform with one
                integration — try MONEI today.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={prestashop_applepay} alt="MONEI apple pay" title="MONEI apple pay" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Apple Pay payments on PrestaShop (and more)</Trans>
              </SectionHeader>
              <Trans parent="p">
                Integrating{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink> with
                your PrestaShop store is a good start, but to reach more customers in Spain and{' '}
                <BlogLink slug="cross-border-payments">across borders</BlogLink>, it’s important to
                accept many payment methods.
              </Trans>
              <Trans parent="p">
                Add more <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <PaypalForPrestashopLink>PayPal</PaypalForPrestashopLink>,{' '}
                <VisaMasterCardForPrestashopLink>
                  credit and debit cards
                </VisaMasterCardForPrestashopLink>
                , and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> to
                your PrestaShop store:
              </Trans>
              <List>
                <Trans parent="li">
                  <BizumForPrestashopLink>Bizum</BizumForPrestashopLink> peer-to-peer (P2P)
                  payments. (Spain)
                </Trans>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
              </List>
              <Trans parent="p">
                …and many more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader sx={{maxWidth: {lg: '595px'}}}>
                <Trans>Improve your PrestaShop conversion rate</Trans>
              </SectionHeader>
              <Trans parent="p">
                Reduce lost sales and{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                by setting up{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4412643058833">
                  payment routing rules
                </AnchorLink>
                . Use <Link to="/features/payments-orchestration/">payments orchestration</Link> to
                choose a default payment processor and if your chosen provider times out, we’ll send
                the transaction to another top-performing one.{' '}
                <Bold>Approve 95% more payments</Bold> and give customers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience by
                not depending on <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> alone to
                process payments.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Save money on your PrestaShop payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Growing your business requires healthy profit margins. Get a{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>{' '}
                that offers <PricingLink>scaled pricing</PricingLink>. This way, as you sell more,
                your transaction fees will decrease in real-time. Save money to reinvest in scaling
                your online business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>Add the PrestaShop Apple Pay integration to your website</Trans>
        </SectionHeader>
        <Trans parent="p">
          Follow these steps to quickly and easily start accepting Apple Pay for PrestaShop:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/en-us/articles/360019794278-How-do-I-activate-Apple-Pay-">
              Configure Apple Pay payments
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop/">
              Connect MONEI
            </AnchorLink>{' '}
            to your PrestaShop store
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default ApplePayForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "apple-pay-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
